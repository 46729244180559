// ------------------------------------ //
//  UI mixins
// ------------------------------------ //

//
//  Input placeholder text color/size
//
@mixin input-placeholder($color, $size:"", $weight:"normal", $textAlign:"left") {
    // do NOT group selectors!

    // WebKit, Blink, Edge
    &::-webkit-input-placeholder {
        color: $color;
        @if $size != "" {
            font-size: $size;
        }
        @if $weight != "" {
            font-weight: $weight;
        }
        @if $textAlign != "" {
            text-align: $textAlign
        }
    }

    // Mozilla Firefox 4 to 18
    &:-moz-placeholder {
        color: $color;
        opacity: 1;
        @if $size != "" {
            font-size: $size;
        }
        @if $weight != "" {
            font-weight: $weight;
        }
        @if $textAlign != "" {
            text-align: $textAlign;
        }
    }

    // Mozilla Firefox 19+
    &::-moz-placeholder {
        color: $color;
        opacity: 1;
        @if $size != "" {
            font-size: $size;
        }
        @if $weight != "" {
            font-weight: $weight;
        }
        @if $textAlign != "" {
            text-align: $textAlign;
        }
    }

    // Internet Explorer 10-11
    &:-ms-input-placeholder {
        color: $color;
        @if $size != "" {
            font-size: $size;
        }
        @if $weight != "" {
            font-weight: $weight;
        }
        @if $textAlign != "" {
            text-align: $textAlign;
        }
    }
}
