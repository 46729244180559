/*------------------------------------*\
    DropdownMenu component
\*------------------------------------*/
@import "../../styles/config/colors";

$dropdown-menu-header-color: $col-green;
$dropdown-menu-item-color: $col-grey;
$dropdown-menu-item-color-hover: $col-white;

.DropdownMenu {
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: $col-white;
  max-height: 275px;
  overflow-y: auto;
}

.DropdownMenu-header {
  margin-bottom: 20px;
  padding: 0;

  color: $dropdown-menu-header-color;

  text-transform: uppercase;
}

.DropdownMenu-icon {
  width: 21px;
  margin-right: 10px;
}

.DropdownMenu-item {
  display: flex;
  align-items: center;
  margin: 0 12px 0 12px;
  padding: 0 8px;
  height: 60px;
  border-bottom: 1px solid $col-grey-medium;
  font-size: 15px;

  color: $dropdown-menu-item-color;

  cursor: pointer;

  &:hover {
    color: $dropdown-menu-item-color-hover;
  }

  &:first-child {
    //padding-top: 15px;
  }
  &:last-child {
    margin-bottom: 0;
    padding-bottom: 15px;
    border-bottom: 0;
  }
}

.DropdownMenu-separator {
  border-top: 1px solid $col-grey;
  padding-top: 20px;
}

.DropdownMenu-disabled {
  color: $col-dove-gray;
  cursor: not-allowed;
  &:hover {
    color: $col-dove-gray;
  }
}
