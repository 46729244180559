.termeniConditii {
  .termeniConditii-content {
    .termeniConditii-paragraph {
      margin-bottom: 20px;
    }

    ul {
      list-style-type: disc;
      margin-left: 40px;
    }

    .container {
      > h1 {
        text-align: center;
        text-transform: uppercase;
      }
    }
  }
}
