.materiale {
  user-select: none;

  .materiale-text {
    padding: 20px;

    h3 {
      text-align: center;
    }

    ul {
      list-style-type: disc;
    }
  }

  .materiale-document {
    min-height: 842px;
  }

  .materiale-pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: default;

    .materiale-paginationBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      i {
        font-size: 20px;
        margin: 0 10px;
      }

      &:hover {
        color: #f1c40f;
      }

      &--disabled {
        color: #f0f0f0;
        cursor: default;

        &:hover {
          color: #f0f0f0;
        }
      }
    }
  }

  canvas {
    margin: 0 auto;
  }
}
