/*------------------------------------*\
    InputField component
\*------------------------------------*/
@import "../../styles/main";
@import '../../styles/mixins';
@import '../../styles/animations';

$fs-larger: 36px;
$fs-mediumlarger: 32px;
$fs-largemedium: 30px;
$fs-mediumlarge: 28px;
$fs-large: 24px;
$fs-largreg: 20px;
$fs-reglarg: 18px;
$fs-regular: 16px;
$fs-small: 14px;
$fs-mdsmall: 12px;
$fs-smaller: 11px;

$search-icon-fill: $col-charcoal;

$background: (
  default: $col-grey-dark,
  disabled: $col-grey,
);

$border: (
  default: $col-grey-dark,
  focus: $color-blue,
  error: $color-red,
  success: $col-green,
);

$input: (
  color: $col-black,
  disabledColor: $col-grey,
  error: $color-red,
  size: 16px,
  lineHeight: 2.5,
  placeholderColor: $col-grey-dark,
);

$label: (
  color: $col-grey-dark,
  size: 16px,
);

$error: (
  color: $color-red,
  size: 13px,
);

.InputField {
  position: relative;

  width: 100%;
  margin-bottom: 20px;

  -webkit-appearance: none;
  height: 43px;
  //padding-left: 40px;
  //padding-right: 40px;
  border-color: $col-charcoal;
  border-radius: 0;
  line-height: 2;
  color: $col-charcoal;

  @include media(xsmall-only) {
    padding-right: 10px;
  }

  @include media(medium-only) {
    padding-right: 10px;
  }

  .lk-form & {
    vertical-align: top;
  }

  &--search{
    display: inline-block;
    width: 360px;
    margin-bottom: 0;
    margin-left: 20px;

    input {
      border-radius: 90px;
    }
  }

  @include media(medium) {
    .lk-my-cause--form & {
      width: 50%;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &--multiline {
    height: 100%;
    .InputField-Input {
      padding-top: 10.5px; // $input.size / 2
      padding-bottom: 10.5px; // $input.size / 2
      line-height: 1;
    }

    .InputField-IconButton {
      top: 10px;
      transform: none;
    }
  }

  &--multiline-resizeable {
    .InputField-Input {
      resize: vertical;
    }
  }

  &--icon {
    .InputField-Input {
      padding-right: 30px;
    }
  }

  &--iconLeft {
    .InputField-Input {
      padding-left: 30px;
    }
  }

  &--required {
    .InputField-InputHolder {
      &:after {
        content: "";

        position: absolute;
        z-index: 2;
        top: 0;
        right: -15px;

        width: 5px;
        height: 5px;

        background: map-get($border, focus);
        border-radius: 50%;
      }
    }
  }

  // &--error {
  //   margin-bottom: 15px;
  //
  //   .InputField-InputHolder {
  //     &:after {
  //       background: map-get($border, error);
  //     }
  //   }
  //
  //   .InputField-Input {
  //     border-color: map-get($border, error);
  //   }
  //
  //   .InputField-IconButton {
  //     display: none;
  //     &--remove {
  //       display: block;
  //     }
  //   }
  // }

  &--success {
    .InputField-Input {
      border-color: map-get($border, success);
    }

    .InputField-IconButton {
      display: none;
    }
  }

  &.disabled {
    .InputField-Input {
      background: map-get($background, disabled);
    }
  }
}

.InputField-InputHolder {
  position: relative;
}

.InputField-Placeholder {
  position: absolute;
  //z-index: $negative-z;
  top: 0;
  left: 50%;

  //font-family: $font-primary-Regular;
  font-size: map-get($label, size);
  font-weight: 400;
  line-height: map-get($input, lineHeight);
  color: map-get($label, color);
  transform: translate3d(-50%, 0, 0);
  pointer-events: none;

  //line-height: 2;
  //color: $col-grey-dark;
  transform-origin: left center;
  transition: all 250ms ease-out;

  @include media(medium-only) {
    font-size: 18px;
    white-space: nowrap;
  }

   .InputField-Input:focus ~ &,
  .InputField-Input--hasValue ~ & {
     // bottom: 0;
     top: -20px;
     left: 0;
     opacity: 1;
     font-size: $fs-smaller;
     line-height: 42px;
     color: $col-charcoal;
     transform: translate3d(0,0,0);
  }
}

.InputField-Input {
  width: 100%;
  height: 45px;
  padding: 0 15px;

  background: transparent;
  border: none;
  border-bottom: 1px solid;
  border-color: map-get($border, default);
  border-radius: 0;
  outline: none;
  resize: none;

  //font-family: $font-primary-Regular;
  font-size: map-get($input, size);
  font-weight: 400;
  line-height: map-get($input, lineHeight);
  color: map-get($input, color);
  vertical-align: middle;
  text-align: center;

  @include input-placeholder(map-get($input, placeholderColor), map-get($label, size), 400);
}

.InputField-Label {
  display: block;

  margin-bottom: 15px;

  //font-family: $font-primary-Regular;
  font-size: map-get($label, size);
  line-height: map-get($label, size) - 2px;
  color: map-get($label, color);

  &:empty {
    margin: 0;
  }
}

.InputField-IconButton {
  position: absolute;
  top: 50%;
  right: 0;

  padding: 0;
  margin: 0;

  background: none;
  border: none;
  outline: none;
  box-sizing: border-box;

  color: $col-white;

  transform: translateY(-50%);
  cursor: default;

  &--remove {
    right: -15px;
  }

  &--clickable {
    cursor: pointer;
  }

  &--left {
    right: inherit;
    left: 0;
  }

  .Icon {
    fill: inherit;
    vertical-align: middle;
    overflow: hidden;

    &--search {
      position: relative;
      top: -2px;
      width: 15px;
      height: 15px;
      vertical-align: inherit;
      fill: $search-icon-fill;
    }
  }
}

.InputField-Error {
  width: 100%;
  padding: 10px 0 0;
  margin: 0;

  background: url(./../../assets/svg/icon-error-black-exclamation-mark.svg) no-repeat left center;

  //font-family: $font-primary-Regular;
  font-size: map-get($error, size);
  font-weight: normal;
  line-height: map-get($input, lineHeight);
  color: map-get($error, color);
  text-align: center;

  animation: fadeInScale 200ms 1;
  background: transparent;
  &:empty {
    padding: 0;
  }
}

// @keyframes focus-pulse {
//   0% {
//     box-shadow: 0 0 2px 1px rgba(map-get($border, focus), 0);
//   }
//   50% {
//     box-shadow: 0 0 2px 1px rgba(map-get($border, focus), 0.3);
//   }
//   100% {
//     box-shadow: 0 0 2px 1px rgba(map-get($border, focus), 0);
//   }
// }

.lk-assetsStyle-Dark {
  .InputField-Input {
    border-color: $col-charcoal;
    color: $col-charcoal;
  }

  .InputField-Placeholder {
    color: $col-charcoal;
  }

  .InputField-Error {
    color: $col-charcoal;
    background: url(./../../assets/svg/icon-error-dark-exclamation-mark.svg) no-repeat left center;
  }

  .InputField-IconButton {
    color: $col-charcoal;
  }

  &.InputField--error {
    margin-bottom: 0;

    .InputField-Input {
      border-color: $color-red;
    }
  }

  .InputField-Input:focus,
  .InputField-Input--hasValue,
  .geosuggest--isFocused,
  .geosuggest--hasValue {
    & ~ .InputField-Placeholder {
      bottom: 0;
      left: 0;
      opacity: 1;
      font-size: $fs-smaller;
      line-height: 42px;
      color: $col-charcoal;
      transform: translate3d(0,0,0);
    }
  }
}
