@mixin media($breakpoint) {
  $map: $breakpoint;

  @if (type-of($breakpoint) != map) {
    $map: map-get($breakpoints, $breakpoint);
  }

  @if $map != null {
    $query: "";
    @if map-has-key($map, min) {
      // $query: append($query, )
      $query: $query + "(min-width: #{map-get($map, min)})"
    }
    @if map-has-key($map, min) and map-has-key($map, max) {
      // $query: append($query, "and")
      $query: $query + " and "
    }
    @if map-has-key($map, max) {
      // $query: append($query, "(max-width: #{map-get($map, max)})")
      $query: $query + "(max-width: #{map-get($map, max)})"
    }
    @media screen and #{$query} {
      @content;
    }
  }

  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
  }
}