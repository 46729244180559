.aplicatie {
  .aplicatie-wrapper {
    .aplicatie-input {
      .InputField-Input {
        text-align: left;
      }
    }

    .aplicatie-ghidaj {
      img {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px auto;
      }
    }
  }
}
