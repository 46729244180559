.Pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f4f4;
  padding: 0.5rem 1rem;
  border: 1px solid #dee2e6;
  border-top: 0;
  //margin-right: 1px;

  > nav {
    margin: 0;
  }

  .Pagination-perPage {
    display: flex;
    align-items: center;
    justify-content: center;

    > span {
      margin-right: 10px;
    }
  }

  .pagination {
    margin-bottom: 0;
  }
}