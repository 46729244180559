@mixin btnScaleDown {
  -webkit-transition: all 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -ms-transition: all 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275);

  &:active {
    -webkit-transition: all 100ms ease-in;
    -webkit-transform: scale(.9);
    -ms-transition: all 100ms ease-in;
    -ms-transform: scale(.9);
    -moz-transition: all 100ms ease-in;
    -moz-transform: scale(.9);
    transition: all 100ms ease-in;
    transform: scale(.9);
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
    transform: translateX(50%) scale(1);
  }

  100% {
    opacity: 0;
    transform: translateX(50%) scale(10);
  }
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.4);
  }

  100% {
    transform: scale(1);
  }
}

@mixin activeBtn {
  animation-name: bounce;
	animation-duration: .5s;
	animation-timing-function: ease;
	animation-iteration-count: 1;
	animation-direction: normal;
	animation-delay: 0;
	animation-play-state: running;
	animation-fill-mode: forwards;
}
