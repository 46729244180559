@import 'main';

@keyframes fadeInScale {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/*
 * ReactCSSTransitionGroup
 */

.SlideHorizontal-enter {
  transform: translateX(100%);
  transition: transform 300ms ease-out;

  &.SlideHorizontal-enter-active {
    transform: translateX(0);
  }
}

.SlideHorizontal-leave {
  transition: transform 300ms ease-in;

  &.SlideHorizontal-leave-active {
    transform: translateX(100%);
  }
}

.FadeIn-enter {
  opacity: 0;
  transition: opacity 300ms ease-out;

  &.FadeIn-enter-active {
    opacity: 1;
  }
}

.FadeIn-leave {
  transition: opacity 300ms ease-in;

  &.FadeIn-leave-active {
    opacity: 0;
  }
}

.HideVertical-enter {
  transition: max-height 300ms ease-out;
  max-height: 0;
  overflow: hidden;

  &.HideVertical-enter-active {
    max-height: 50px;
  }
}

.HideVertical-leave {
  transition: max-height 300ms ease-out;
  max-height: 50px;
  overflow: hidden;

  &.HideVertical-leave-active {
    max-height: 0;
  }
}
