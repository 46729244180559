.checkbox {
  display: inline-block;
  vertical-align: middle;

  .checkbox-icon {
    fill: none;
    stroke: white;
    stroke-width: 2px;
  }

  .checkbox-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    clippath: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;

    &:focus {
      box-shadow: 0 0 0 3px pink;
    }
  }

  .checkbox-styled {
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    transition: all 150ms;
    border: 1px solid #f0f0f0;
  }
}
