.cursPage {
  h2 {
    margin-top: 30px;
  }

  .cursPage-descriere {
    ul {
      list-style: initial;
      margin-left: 30px;
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 20px;
    }
  }
}
