.steps {
  display: flex;
  background-color: aliceblue;

  .steps-step {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    vertical-align: top;
    flex: 1;
    margin-right: 22px;
    color: #666;
    cursor: pointer;

    &:hover {
      color: rgba(0, 0, 0, 0.43);
    }

    &:last-child {
      margin-right: 0;
    }

    &:after {
      content: '';
      display: inline-block;
      width: 16px;
      height: 16px;
      border: 1px solid #ccc;
      border-bottom: none;
      border-left: none;
      transform: rotate(45deg) translateY(-50%);
      position: absolute;
      top: 50%;
      left: 100%;
      margin-top: -2px;
      margin-left: -8px;
    }

    &:last-child:after {
      display: none;
    }

    .steps-stepWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 0 7px 0;
      border-bottom: 3px solid transparent;

      &:hover {
        border-bottom: 3px solid #ccc;
      }
    }

    .steps-stepNo {
      border: 1px solid #ccc;
      width: 26px;
      height: 26px;
      line-height: 26px;
      text-align: center;
      border-radius: 26px;
      font-size: 14px;
      margin-right: 8px;
      transition: background-color 0.3s, border-color 0.3s;

      & > span {
        font-weight: bold;
        line-height: 1;
        top: -1px;
        position: relative;
      }
    }

    &--selected {
      color: rgb(16, 142, 233);

      &:hover {
        color: rgba(16, 142, 233, 0.43);
      }

      .steps-stepWrapper {
        border-bottom: 3px solid #108ee9;

        &:hover {
          border-bottom: 3px solid #72B1F4;
        }

        .steps-stepNo {
          border-color: #108ee9;
          background-color: #108ee9;

          & > span {
            color: #fff;
          }
        }
      }
    }

    &--disabled {
      color: #ccc;
      cursor: default;

      &:hover {
        color: #ccc;
      }

      .steps-stepWrapper {
        border-bottom: 3px solid transparent;

        &:hover {
          border-bottom: 3px solid transparent;
        }

        .steps-stepNo {
          border-color: #ccc;
          background-color: #ccc;

          & > span {
            color: #fff;
          }
        }
      }
    }
  }
}

