.courseStep {
  max-width: 300px;
  min-width: 300px;
  cursor: pointer;
  flex: 1;
  display: flex;
  flex-direction: column;
  user-select: none;

  &:hover {
    .why-choose-icon {
      color: #f1c40f;
    }
  }

  .why-choose-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #011e40;
    font-size: 50px;
  }

  .why-choose-text {
    height: 100%;
  }

  &--disabled {
    cursor: default;

    img {
      filter: grayscale(100%);
    }

    .why-choose-text {
      color: #8c8c8c;

      h2 a {
        color: #8c8c8c;

        &:hover {
          color: #8c8c8c;
        }
      }
    }

    .why-choose-icon {
      border-color: #8c8c8c;
      color: #8c8c8c;
    }

    &:hover {
      h2 a {
        cursor: default !important;
        color: #8c8c8c !important;
      }

      .why-choose-icon {
        border-color: #8c8c8c;
        color: #8c8c8c;
      }
    }
  }
}
