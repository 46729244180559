.seriesAdmin {
  .seriesAdmin-filtersWrapper {
    .seriesAdmin-dropdown, .seriesAdmin-input {
      max-width: 300px;
      min-width: 300px;
      display: inline-block;
      margin-right: 20px;
    }
  }
}
