@import "../../styles/config/colors";

.SidebarWrapper {
  position: sticky;
  //top: 4rem;
  z-index: 1000;
  min-height: calc(100vh - 48px);
  background-color: $col-white;
  border-right: 1px solid $colorBg;
  flex-grow: initial;
  padding: 0;

  .SidebarNav {
    padding: 2rem 0;
    min-height: 90vh;

    overflow: hidden;
    display: block!important;
    height: 100%!important;
    width: 48px;

    &--expanded {
      width: 250px;
    }

    .SidebarNav-wrapper {
      height: 100%!important;
      overflow: auto;

      .SidebarNav-item {
        overflow: hidden;
        white-space: nowrap;
        height: 48px;

        .SidebarNav-link {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          overflow: hidden;
          white-space: nowrap;
          padding: 0;
          //font-size: 1.3rem;
          color: $colorText02;

          &--folder {
            font-weight: 600;
          }

          &--active {
            position: relative;
            border-radius: 0;
            color: #161616;
            font-weight: 600;
            background-color: #e0e0e0;

            &:before {
              width: 4px;
              background-color: $colorSelected;
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              //width: 4px;
              //background-color: #0f62fe;
              content: '';
            }
          }

          .SidebarNav-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 48px;
            min-width: 48px;
            height: 48px;
          }

          &--selected {
            background-color: #c6c6c6;
            color: #525252;
          }

          &:hover {
            color: #161616;
            background-color: #e5e5e5;
          }
        }
      }
    }
  }

  &--mobile {
    display: none!important;
    position: relative;
  }

  &--mobileExpanded {
    position: fixed;
    display: block!important;
    left: 0;
    right: 0;
    top: 60px;
    bottom: 0;
    overflow: hidden;
    //width: 100%;
    //margin-left: -15px;
    //margin-right: -15px;

    .SidebarNav--expanded {
      width: 100%;
    }
  }

}
