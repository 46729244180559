.partners {
  .partners-link {
    img {
      &:hover {
        filter: none;
      }
    }
  }

  .row {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
