.courseView {
  .courseView-steps {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    & > div {
      margin: 20px;
    }
  }
}
