.inscriere-page {

  .inscriere-error {
    display: flex;
    justify-content: center;
    width: 100%;
    //padding: 10px 0 0;
    margin: 0;
    //background: url(/static/media/icon-error-black-exclamation-mark.a7ea0bd9.svg) no-repeat left center;
    font-size: 13px;
    font-weight: normal;
    line-height: 2.5;
    color: #f15a5a;
    text-align: center;
    animation: fadeInScale 200ms 1;
    background: transparent;
  }

  .inscriere-addSchool {
    .inscriere-addSchoolIcon {
      font-size: 20px;
      width: 20px;
      height: 20px;
      color: green;
    }

    cursor: pointer;
  }

  .pl-0 {
    padding-left: 0;
  }
}
