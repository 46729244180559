@import "../../styles/config/colors";

.DataTable {
  overflow-y: auto;

  .table-responsive {
    overflow: inherit !important;
  }

  .DataTable-topArea {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #f4f4f4;

    .DataTable-buttonArea {
      padding: 0.5rem 1rem;
      display: flex;
      justify-content: flex-end;
    }
  }


  .table {
    color: #525252;

    thead {
      background-color: var(--white);
      text-align: center;

      th {
        background-color: #e0e0e0;;
        border: #e0e0e0;;
        color: #152935;
      }
    }

    th, td {
      border: none;
      vertical-align: middle;
      min-height: 3rem;
      height: 48px;

      a {
        color: #525252;
      }
    }

    .DataTable-row {
      &--clickable {
        cursor: pointer;
      }

      .DataTable-cell {
        &--icon {
          vertical-align: middle;

          &:hover {
            background-color: #cacaca;
            //background-color: crimson;
          }

          > div {
            display: flex;
            justify-content: center;

            > a {
              flex: 1;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }

      td:first-child {
        padding-left: calc(0.3rem + 4px);
      }

      &:hover {
        background-color: #e5e5e5;

        td:first-child {
          position: relative;

          &:before {
            width: 4px;
            background-color: $colorSelected;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            //width: 4px;
            //background-color: #0f62fe;
            content: '';
          }
        }
      }
    }

    margin-bottom: 0;
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: #f4f4f4;
  }

  .DataTable-emptyBody {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    background-color: rgba(0, 0, 0, 0.05);

    p {
      white-space: pre;
      text-align: center;
    }
  }
}
