$col-charcoal: #3f3f3f;
$col-tundora: #484848;
$col-black: #000;
$col-mine-shaft: #262626;
$col-white: #fff;
$col-grey-darker: #9b9b9b;
$col-grey-dark: #898989;
$col-grey-medium: #c3c3c3;
$col-grey-light: #f2f2f2;
$col-grey: #e2e2e2;
$col-dove-gray: #6a6a6a;
$col-dark-grey: #262626;
$col-lightdark-grey: #313131;
$col-dirty-white: #f7f7f7;
$col-lighter-gray: #d7d7d7;
$col-light: $col-white;
$col-dark: $col-charcoal;
$col-btn: #111111;
$colorBg: #f5f5f5;
$colorText02: #525252;
$colorSelected: #0f62fe;

$col-green: #1fc796;
$color-blue: #5cb4ff;
$color-red: #f15a5a;
$col-dark-red: #df4962;
$color-mariner: #2b7cc0;
$color-algae-green: #7de0a7;
$col-aero-blue: #cfffe4;
$col-azure: #0090ff;
$col-dodger-blue: #49b0ff;
$col-facebook: #3b5998;

// main colors:
$col-main-blue: #0a72d2;
$col-main-blue-font: #3385A6;

// secondary colors:
$col-secondary-grey-light: #ebebeb;
$col-secondary-grey: #b3b3b3;
$col-secondary-grey-dark: #767676;
$col-secondary-blue-dark: #125084;
$col-secondary-blue-black: #03141e;
$col-secondary-error: #f00;

$color_main: #f80f8a;
$color_secondary: #ffb5c0;
$color_dark: #696969;
$color_darker: #333;
$color_white: #fff;
$color_black: #000;
