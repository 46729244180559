.subscriptionsAdmin {
  .subscriptionsAdmin-filtersWrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .subscriptionsAdmin-dropdown, .subscriptionsAdmin-input {
      max-width: 300px;
      min-width: 300px;
      display: inline-block;
      margin-right: 20px;
      margin-bottom: 1rem!important;
    }
  }
}
